<template>
  <div class="text-center">
    <v-dialog
        v-model="showDialog"
        width="700"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        content-class="company-info-dialog"
    >
      <v-card v-if="user">
        <v-card-text>
          <user-info class="mt-7" :user="user" @applyForJob="$emit('applyForJob', $event)"/>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="showDialog=false"
          >
            Schließen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import UserInfo from '@/components/UserInfo'
export default {
  name: "UserInfoDialog",
  components: { UserInfo },
  data() {
    return {
      user: undefined,
      showDialog: false,
      test: 'bla',
      posts: [],
    }
  },
  methods: {
    show(user) {
      this.user = user
      this.showDialog = true
    },
  },
}
</script>

<style scoped>
</style>
