<template>
  <v-app>
    <div class="heading">
      <img class="logo" src="https://www.mission-stuckateur.de/wp-content/uploads/2019/02/Mission-STKTR-schwarzweiss.png.webp">
    </div>
    <div class="menu">
      AUSBILDUNGSNAVIGATOR
    </div>
    <v-main>
      <router-view/>
    </v-main>
    <my-snack-bar ref="mySnackBar"/>
  </v-app>
</template>

<script>

import {eventBus} from '@/main';
import MySnackBar from '@/components/MySnackBar.vue';

export default {
  name: 'App',
  components: {MySnackBar},
  mounted() {
    eventBus.$on('snackbar-success', (message) => {
      this.$refs.mySnackBar.success(message);
    })
    eventBus.$on('snackbar-error', (message) => {
      this.$refs.mySnackBar.error(message);
    })
  }
};
</script>
<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';
.heading {
  margin-top: 20px;
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    margin-left: auto;
    margin-right: 300px;
    .logo {
      max-width: 562px;
    }
  }
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    margin-left: auto;
    margin-right: auto;
    .logo {
      max-width: 350px;
    }
  }
}
.menu {
  background-color: #008bc5;
  height: 40px;
  color: white;
  font-weight: bold;
  line-height: 40px;
  font-size: 16px;
  padding-left: 30px;
  margin-top: 20px;
}
</style>