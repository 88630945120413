<template>
  <div class="text-center">
    <v-dialog
        v-model="showDialog"
        v-if="showDialog"
        width="700"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        content-class="job-application-dialog"
    >
      <v-card v-if="user">
        <v-card-title  style="background-color: #008bc5; color: white;">{{ user.companyName }}</v-card-title>
        <v-card-text>
          <v-form ref="form">

            <v-row id="user-info" class="mx-3 mt-3">
              <v-col cols="12" md="12">
                <img class="logo mt-2" v-if="user.logoName" :src="getLogoUrl(user)">

              </v-col>
              <v-col cols="12" md="12">
                Danke für Dein Interesse an unserem Betrieb! Wir freuen uns sehr darüber und bitten Dich nachfolgend um ein paar Kontaktdaten. Gerne melden wir uns in den nächsten Tagen bei Dir zurück.
              </v-col>
              <v-input  :rules="generateGroupRules">
                <v-row class="ml-1">
                  <v-col cols="12" md="12">
                    <header>Ich interessiere mich für*:</header>
                  </v-col>
                  <v-col cols="12" md="6" :key="group.id"
                         v-for="group in sortedUserGroups(user)"
                         class="py-0"
                  >
                    <v-checkbox :label="group['text']"
                                class="py-0"
                                v-model="jobApplication.groups[group['text']]"
                    />
                  </v-col>
                </v-row>
              </v-input>
              <v-col cols="12" md="12">
                <v-text-field v-model="jobApplication.firstName" label="Vorname*" :rules="[rules.required]"/>
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field v-model="jobApplication.lastName" label="Nachname*" :rules="[rules.required]"/>
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field v-model="jobApplication.email" label="Deine E-Mail-Adresse*" :rules="[rules.required, rules.email]"/>
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field v-model="jobApplication.phone" label="Telefon" :rules="[rules.phoneNumber]"/>
              </v-col>
              <v-col cols="12" md="12">
                <v-textarea v-model="jobApplication.message" label="Deine Nachricht" :rules="[rules.required]"/>
              </v-col>
              <v-col cols="12" md="12">
                <v-checkbox
                    v-model="jobApplication.terms"
                    :rules="[rules.requiredCheckbox('Sie müssen der Datenschutzerklärung zustimmen.')]"
                >
                  <template v-slot:label>
                      <span>
                        Ich habe die
                        <a href="https://www.mission-stuckateur.de/datenschutz" target="_blank" @click.stop>Datenschutzerklärung</a> gelesen *
                      </span>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="showDialog=false"
          >
            Abbrechen
          </v-btn>
          <v-btn
              color="primary"
              text
              @click="sendJobApplication"
          >
            Senden
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog></div>
</template>

<script>

import APIService from '@/services/APIService';
import {getLogoUrl, snackbar} from '@/utils/common';
import {getCategoryText, sortedUserGroups} from '../utils/categoriesData';
export default {
  name: "JobApplicationDialog",
  data() {
    return {
      user: undefined,
      showDialog: false,
      test: 'bla',
      posts: [],
      jobApplication: {
        groups: {}
      },
      rules: {
        required(value) {
          return !!value || 'Erforderlich'
        },
        phoneNumber: (value) => {
          if (value === '' || value === undefined) {
            return true;
          }
          const phoneNumberPattern = /^[0-9()-+ ]+$/;
          return phoneNumberPattern.test(value) || 'Ungültige Telefonnummer';
        },
        email: (value) => {
          const emailPattern = /^[a-zA-Z0-9._-äöüÄÖÜß]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
          return emailPattern.test(value) || 'Invalid email address';
        },
        requiredCheckbox(errorMessage) {
          return v => v || errorMessage;
        }
      },
    }
  },
  methods: {
    sortedUserGroups,
    getCategoryText,
    show(company) {
      this.user = company
      this.showDialog = true
    },
    async sendJobApplication() {
      if (!this.$refs.form.validate()) {
        return
      }
      this.$refs.form.validate()
      this.$emit('sendJobApplication', this.jobApplication)
      // eslint-disable-next-line no-unused-vars
      const {groups, ...payload} = this.jobApplication
      APIService.sendJobApplication(this.user.id, {
        ...payload,
        applyFor: Object.keys(this.jobApplication.groups)
      }).then(() => {
        snackbar.success("Deine Anfrage wurde versendet")
        this.showDialog = false
      }).catch(() => snackbar.error("Die Anfrage konnte nicht versendet werden. Bitter versuchen Sie es später erneut."))
    },
    getLogoUrl
  },
  computed: {
    generateGroupRules() {
      const groups = this.jobApplication.groups
      return [Object.keys(groups).length > 0 && Object.keys(groups).map(key => groups[key]).some(val => val === true) || 'Auswahl erforderlich']
    },
  }
}
</script>

<style scoped>
#user-info {
  .field .field-name {
    font-weight: bolder;
    color: #747474;
  }

  .logo {
    max-height: 250px;
  }

  .offers {
    header {
      text-decoration: underline;
      font-weight: bolder;
    }
  }
}
</style>
