<template>
  <div>
    <div style="min-height: 450px;" id="map"></div>
    <user-info-dialog ref="companyInfoDialog" @applyForJob="$emit('applyForJob', $event)"/>
  </div>
</template>

<script>

import UserInfoDialog from "@/components/UserInfoDialog";

export default {

  name: "GoogleMaps",
  components: {UserInfoDialog},
  props: {
    companies: {
      type: Array,
      required: true,
    },
    lat: {
      type: Number,
      default: 48.808220,
    },
    lng: {
      type: Number,
      default: 8.945020,
    },
    zoom: {
      type: Number,
      default: 8,
    },
    radius: {
      type: Number,
    }
  },
  data() {
    return {
      map: undefined,
      markers: [],
      centerCircle: undefined
    }
  },
  methods: {
    updateMap() {
      if (this.markers) {
        let i;
        for (i in this.markers) {
          this.markers[i].setMap(null);
        }
      }
      this.markers.length = 0;
      if(this.centerCircle) {
        this.centerCircle.setMap(null)
      }
      // eslint-disable-next-line no-undef
      let bounds = new google.maps.LatLngBounds();
      this.companies.forEach((company) => {
        const map = this.map
        if(company.lat) {
          const icon =  'https://www.stuck-verband.de/wp-content/plugins/w2dc/resources/images/map_icons/blank.png'
          // eslint-disable-next-line no-undef
          const marker = new google.maps.Marker({
            position: {lat: parseFloat(company.lat), lng: parseFloat(company.lng)},
            // eslint-disable-next-line no-undef
            animation: google.maps.Animation.DROP,
            icon,
            map,
          });
          marker.addListener("click", () => {
            this.$refs.companyInfoDialog.show(company)
          });
          this.markers.push(marker)
          // eslint-disable-next-line no-undef
          bounds.extend(marker.position);
        }
      })
      const that = this;
      var zoomChangeBoundsListener =
          // eslint-disable-next-line no-undef
          google.maps.event.addListener(this.map, 'bounds_changed', function() {
            // eslint-disable-next-line no-undef
            google.maps.event.removeListener(zoomChangeBoundsListener);
            that.map.setZoom( Math.min( that.zoom, that.map.getZoom() ) );
          });
      if(this.markers.length > 0) {
        this.map.fitBounds(bounds);
      }
      // eslint-disable-next-line no-undef
      this.centerCircle = new google.maps.Circle({
        strokeColor: "#FF0000",
        strokeOpacity: 0.2,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.1,
        map: this.map,
        center: { lng: this.lng, lat: this.lat},
        radius: this.radius * 1000,
      });
    }
  },
  mounted() {
    // eslint-disable-next-line no-undef
    this.map = new google.maps.Map(document.getElementById("map"), {
      zoom: this.zoom,
      center: { lat: this.lat, lng: this.lng },
      styles: [
        {
          featureType: "poi",
          stylers: [{ visibility: "off" }],
        }
      ],
      fullscreenControl: false,
    });
    const that = this;
    // eslint-disable-next-line no-undef
    const boundsChangedListener = google.maps.event.addListener(this.map, 'bounds_changed', function() {

      const bounds =that.map.getBounds()
      if( bounds.getNorthEast().lat() !== bounds.getSouthWest().lat()) {
        // eslint-disable-next-line no-undef
        google.maps.event.removeListener(boundsChangedListener)
        that.updateMap()
      }
    });
  },
  watch: {
    companies: function() {
      this.updateMap()
    },
    lat: function() {
      // eslint-disable-next-line no-undef
      this.map.setCenter(new google.maps.LatLng(this.lat, this.lng));
    },
    lng: function() {
      // eslint-disable-next-line no-undef
      this.map.setCenter(new google.maps.LatLng(this.lat, this.lng));
    }
  }
}

</script>

<style scoped>
</style>
