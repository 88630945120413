import axios from 'axios';

const config = {
    baseURL: `${process.env.VUE_APP_SERVER}/v1`,
    withCredentials: false, // This is the default
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    },
}
const apiClient = axios.create(config)

export default {
    getEducationCompanies: async function () {
        return apiClient.get(`/users/educationCompanies`).then(result => result.data)
    },
    sendJobApplication: async function (userId, payload) {
        return apiClient.post(`/users/${userId}/sendJobApplication`,  payload )
    },
}
