import {eventBus} from '@/main';

export const snackbar = {
    success: (message) => {
        eventBus.$emit('snackbar-success', message)
    },
    error: (message) => {
        eventBus.$emit('snackbar-error', message)
    },
}
export const getLogoUrl = (user) => {
    if (user.logoName) {
        return `https://api.backend.digital.stuck-komzet.de:444/public/logos/${user.logoName}`
    } else {
        return "https://www.stuck-verband.de/wp-content/uploads/2015/11/betriebebild.jpg"
    }
}