<template>
  <div id="outer">
    <div class="heading">AUSBILDUNG ODER PRAKTIKUM FINDEN</div>
    <div class="sub-heading">
      Beruf auswählen. Wohnort oder Postleitzahl eintragen. Suchradius einstellen – und los!
    </div>

    <div id="search-input">
      <v-text-field label="PLZ / Ort" v-model="searchText" append-icon="mdi-map-marker"/>
      <v-checkbox
          class="my-0 py-0"
          v-for="category in categories"
          :key="category.value"
          :label="category.text"
          :value="category.value"
          v-model="checkboxModel"
      ></v-checkbox>
      <v-slider
          class="mb-4"
          color="#008bc5"
          :hint="`Suchradius: ${dist} Kilometer`"
          persistent-hint
          max="100"
          min="1"
          v-model="dist"
          append-icon="mdi-ruler"
      ></v-slider>
      <v-btn class="white--text" color="#008bc5" @click="startSearch">Suchen</v-btn>
      <br>
    </div>
    <template v-if="home.lat">
      <div>
        Anzahl Betriebe: {{ companiesInRange.length }}
      </div>
      <google-maps @applyForJob="applyForJob" :companies="companiesInRange" :lat="home.lat" :lng="home.lng"
                   :zoom="home.lat ? 12 : 8" :radius="dist"/>

      <div id="search-result" :key="company.id" v-for="company in companiesInRange">
        <hr>
        <UserInfo :user="company" @applyForJob="applyForJob"/>
      </div>
    </template>
    <div style="font-size: 1.2em" v-else>PLZ oder Ort bitte oben eintragen.</div>
    <div class="register-company">
      Sie sind Ausbildungsbetrieb und möchten auf dieser Seite gelistet werden? Dann klicken Sie >>
      <a href="https://shop.ausbau-akademie.de/produkt/registrieren-als-ausbildungsbetrieb-kostenfrei/" target="_blank"> hier.</a>
    </div>
    <job-application-dialog ref="jobApplicationDialog"/>
  </div>
</template>

<script>
import axios from "axios";
import GoogleMaps from "@/components/GoogleMaps";
import UserInfo from "@/components/UserInfo";
import JobApplicationDialog from "@/components/JobApplicationDialog";
import APIService from '@/services/APIService';
import {categories} from '@/utils/categoriesData';

export default {
  name: "SearchView",
  components: {GoogleMaps, UserInfo, JobApplicationDialog},
  data() {
    return {
      companies: [],
      dist: 7,
      home: {
        lat: undefined,
        lng: undefined
      },
      searchText: '',
      result: '',
      selectedCategories: [],
      categories,
    }
  },
  methods: {
    distance(lat1, lon1, lat2, lon2, unit) {
      if ((lat1 === lat2) && (lon1 === lon2)) {
        return 0;
      } else {
        var radlat1 = Math.PI * lat1 / 180;
        var radlat2 = Math.PI * lat2 / 180;
        var theta = lon1 - lon2;
        var radtheta = Math.PI * theta / 180;
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
          dist = 1;
        }
        dist = Math.acos(dist);
        dist = dist * 180 / Math.PI;
        dist = dist * 60 * 1.1515;
        if (unit === "K") {
          dist = dist * 1.609344
        }
        if (unit === "N") {
          dist = dist * 0.8684
        }
        return dist;
      }
    },
    async startSearch() {
      this.result = await axios.get('https://api.opencagedata.com/geocode/v1/json', {
        params: {
          q: this.searchText,
          key: '2063afef852a4847a864640a84e86344',
          language: 'de',
          countrycode: 'de',
          pretty: 1,
        },
      }).then(result => result.data.results).catch(() => this.home = {lat: undefined, lng: undefined})
      this.home = this.result.length > 0 ? this.result[0].geometry : ''
    },
    categoryFilter(company) {
      if (!this.selectedCategories || this.selectedCategories.length === 0) {
        return true;
      }
      return company.userGroups.some(userGroup => this.selectedCategories.includes(userGroup));
    },
    applyForJob(company) {
      this.$refs.jobApplicationDialog.show(company)
    }
  },
  computed: {
    companiesInRange() {
      console.log("Companies", this.companies)
      return this.home.lat ? this.companies
          ?.map(c => {
            c.distance = this.distance(c.lat, c.lng, this.home.lat, this.home.lng, "K");
            return c
          })
          .filter(c => c.distance < this.dist)
          .filter(this.categoryFilter)
          .sort((a, b) => a.distance - b.distance) : []
    },
    checkboxModel: {
      get() {
        // Return an array of values that represent the selected checkboxes
        return this.selectedCategories;
      },
      set(value) {
        // Update the selectedCategories array based on checkbox changes
        this.selectedCategories = value;
      }
    }
  },
  async mounted() {
    this.companies = await APIService.getEducationCompanies()
  }
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

#outer {
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    width: 800px;
    margin-left: auto;
    margin-right: auto
  }
}

#search-input {
  background: none;
  padding: 30px 10px;

  .v-input__control {
    background-color: white;
  }
}

#search-result {
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    padding: 0 15px;
  }
}

.heading {
  font-weight: bolder;
  margin-top: 30px;
  color: #008bc5;
  font-size: 2.1em;
}

.sub-heading {
  color: #008bc5;
  font-size: 1.2em;
  font-weight: bolder;
}
</style>
