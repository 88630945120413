// categoriesData.js
const categories = [
  {
    text: "Ausbildung Stuckateur",
    value: "sy_ast"
  },
  {
    text: "Ausbildung Ausbau-Manager",
    value: "sy_aam"
  },
  {
    text: "Ausbildung Maler",
    value: "sy_ama"
  },
  {
    text: "Ausbildung Trockenbaumonteur",
    value: "sy_atb"
  },
  {
    text: "Praktikum",
    value: "sy_pra"
  },
];

function getCategoryText(value) {
  const category = categories.find(category => category.value === value);
  return category ? category.text : null;
}
function sortedUserGroups(user) {
  return categories
      .filter(category => user.userGroups.includes(category.value));
}

export { categories, sortedUserGroups , getCategoryText };
