<template>
  <v-row id="user-info">
    <v-col cols="12" md="6">
      <img class="logo mt-2" :src="getLogoUrl(user)">
    </v-col>
    <v-col cols="12" md="6">
      <h3>{{ user.companyName }}</h3>
      <div class="field">
        <span class="field-name">Telefon:</span> {{ phone }}
      </div>
      <div class="field">
        <span class="field-name">E-Mail: </span><a :href="`mailto:${email}`">{{ email }}</a>

      </div>
      <div class="field">
        <span class="field-name">Homepage:</span> <a :href="`https://${website}`"
                                                     target="_blank">{{ website }}</a>

      </div>
      <div class="field">
        <span class="field-name">Adresse:</span> {{ user.street }}, {{ user.zip }} {{ user.city }}
      </div>
      <div>
        Entfernung: {{ user.distance.toFixed(1).replace('.', ',') }} Kilometer
      </div>
      <div class="offers my-3">
        <header>Wir bieten:</header>
        <div :key="group.id"
             v-for="group in sortedUserGroups(user)">
          {{ group["text"] }}
        </div>
      </div>
      <div class="apply-for-Job">
        <v-btn color="primary" class="mb-3" @click="$emit('applyForJob', user)">Jetzt bewerben</v-btn>
      </div>
      <div></div>
    </v-col>
  </v-row>
</template>

<script>
import APIService from '@/services/APIService'
import {getLogoUrl} from '@/utils/common';
import {categories, sortedUserGroups, getCategoryText} from '@/utils/categoriesData';
export default {
  name: "ComponentInfo",
  computed: {
    APIService() {
      return APIService
    },
  },
  props: {
    user: {
      required: true,
      type: Object,
    }
  },
  data() {
    return {
      email: null,
      phone: null,
      website: null,
      categories,
    }
  },
  methods: {
    sortedUserGroups,
    getCategoryText,
    getLogoUrl,
    findContacts(data) {
      const found = { E: false, T: false, U: false };
      const sortedData = data.sort((a, b) => a.RF - b.RF)
      for (const item of sortedData) {
        if (!found.E && item.TelefonTyp === 'E') {
          this.email = item.TelefonNrUnformatiert;
          found.E = true;
        } else if (!found.T && item.TelefonTyp === 'T') {
          this.phone = item.TelefonNrUnformatiert;
          found.T = true;
        } else if (!found.U && item.TelefonTyp === 'U') {
          this.website = item.TelefonNrUnformatiert?.replace(/^(http:\/\/|https:\/\/)/, '');
          found.U = true;
        }
        if (found.E && found.T && found.U) break;
      }
    }
  },
  watch: {
     user() {
       this.findContacts(this.user.phoneNumbers);
     }
  },
  mounted() {
    this.findContacts(this.user.phoneNumbers);
  }
}
</script>

<style lang="scss" scoped>
#user-info {
  .field .field-name {
    font-weight: bolder;
    color: #747474;
  }

  .logo {
    max-width: 300px;
  }

  .offers {
    header {
      text-decoration: underline;
      font-weight: bolder;
    }
  }
}
</style>